export default {
  navbar: {
    home: "Home",
    about: "About Us",
    support: "Support Request",
    info: "Information Center",
    general_info: "General Informations",
    product_info: "Product Informations",
    documents: "Documents",
    settings: "Settings",
    profile: "My Profile",
    signout: "Sign Out",
    login: "Login",
    register: "Register",
  },
  header: {
    recommend: "We Recommend For You",
  },
  footer: {
    contact: "Contact",
    pages: "Pages",
    text: "Our mission is to provide the highest quality service to our customers, and we always aim to prioritize customer satisfaction above all else.",
  },

  previous: "Previous",
  next: "Next",
  unsp: "Unspecified",
  month: "Month",
  save: "Save",
  cancel: "Cancel",
  add_basket: "Add to Cart",
  remove_basket: "Take out of the cart",
  type_product: "Product",
  type_software: "Sofware",
  very_soon: "Very Soon",
  contact_me: "Contact Me",
  contact_for_demo: "Contact For Demo",
  no_results: "There were no results",
  download_file: "Download File",
  go_invoice: "Go to Invoice",
  go_document: "Go to Document",
  yes: "Yes",
  no: "No",
  save_changes: "Save Changes?",
  and: "and",

  monthly: "Monthly",
  for_once: "One-Time Payment",
  no_surprise: "No Surprise Price",
  new:"New",

  pages: {
    login: "Login",
    register: "Register",
    password_forgot: "I forgot my password",
    home: "Home",
    about: "About",
    info: "Information Center",
    general_info: "General Informations",
    product_info: "Product Informations",
    documents: "Documents",
    support: "Support Request",
    basket: "My Cart",
    pay: "Pay",
    billing_info: "Billing Information",
    profile: "My Profile",
    payments: "My Payments",
  },
  home: {
    all: "All",
    sug_rank: "Suggested Ranking",
    inc_price: "Increasing Price",
    desc_price: "Decreasing Price",
  },
  account: {
    bar: {
      profile: "MY PROFİLE",
      products: "MY PRODUCTS",
      payments: "MY PAYMENTS",
      support: "SUPPORT REQUEST",
      settings: "SETTINGS",
      signout: "SIGN OUT",
    },
    settings: {
      account_info: "Account Info",
      change_email: "Change Email",
      change_pass: "Change Password",
      labels: {
        name: "Name",
        surname: "Surname",
        phone: "Phone",
        country: "Country",
        company: "Company",
        current_email: "Current Email",
        new_email: "New Email",
        current_pass: "Current Password",
        new_pass: "New Password",
        new_pass_again: "New Password Again",
      },
    },
    profile: {
      email: "Email",
      company: "Company",
      phone: "Phone",
      country: "Country",
      reg_date: "Date of registration",
    },
    products: {
      preparing: "Preparing",
      use: "In Use",
      total_time: "Total Time",
      remaining_time: "Remaining ime",
      start_date: "Starting date",
      end_date: "Due Date",
      order_date: "Order Date",
      expired: "Expired",
      returned: "Has Been Returned",
      return: "Return It",
      returned_message: "Are you sure you want to return the product?",
    },
    payments: {
      date: "Payment Date",
      amount: "Total Amount",
      installment: "Installment",
    },
  },
  invoice: {
    details: "Invoice Details",
    number: "Invoice Number",
    name: "Name",
    surname: "Surname",
    phone: "Phone",
    city: "City",
    district: "District",
    post_code: "Post Code",
    address: "Address",
    date: "Date",
    datatable: {
      product: "Product",
      package: "Package",
      time: "Time(Month)",
      price: "Price",
      start_date: "Starting date",
      end_date: "Due Date",
    },
    shoptable: {
      number: "Total Number of Products",
      amount: "Total amount",
    },
  },
  supports: {
    head: "How can we help you",
    head2: "My Support Requests",
    text: "Our mission is to provide the highest quality service to our customers, and we always aim to prioritize customer satisfaction above all else.",
    create: "Create Support Request",
    search: "Search Support Request",
    status: "Status",
    title: "Header",
    description: "Description",
    send_date: "Sending Date",
    send: "Submit Support Request",
    waiting: "Waiting For Response",
    answered: "Answered",
    solved: "Solved",
    new_message: "New Message",
    send_new_message: "New Message Send",
  },
  product: {
    info: "Product Information",
    document: "Review Document",
    view: "View Product",
    features: "Distinctive Features",
    prices: "Prices",
    price_table: {
      features: "Features",
    },
    shipping_fee: "Shipping Fee",
    installment: "Installment",
  },
  shop: {
    basket: "My Cart",
    clean: "Clean up the cart",
    view: "View Products",
    search: "Search in cart",
    confirm_cart: "Confirm Cart",
    confirm: "Confirm",
    no_item_found: "No Item Found in Your Cart",

    read_and_accept: "I have read and I accept.",
    distance_selling: "Distance Selling Contract",

    payment_options: "Payment Options",
    installment: "Installment",
    oneshot: "One Shot",

    order_info: "Order Information",
    delivery_info: "Delivery Information",
    payment_info: "Payment Information",
    gotocart: "Go to Cart",
    gotoproduct: "Go to Product",

    pay: "Pay",
    continue: "Continue",
    goback: "Go Back",

    datatable: {
      product: "Product",
      package: "Package",
      time: "Time(Month)",
      piece: "Piece",
      price: "Price",
    },
    shopcart: {
      number: "Total Number of Products",
      subtotal: "Subtotal",
      amount: "Total Amount",
    },

    labels: {
      city: "City",
      district: "District",
      post_code: "Post Code",
      address: "Address",
      additional_info: "Additional Info",
      card_number: "Card Number",
      exp_date: "Expiration date",
    },
  },
  auth: {
    login: "Login",
    register: "Register",
    send: "Send",
    verify: "Verify",
    change_my_password: "Change My Password",
    heads: {
      login: "LOGIN",
      register: "REGISTER",
      forgot_pass: "I FORGOT MY PASSWORD",
    },
    forgot_text:
      "Please enter your e-mail address. You will receive a code to create a new password via email.",
    forgot_code_text:
      "One last step left. You can renew your password by entering the code we have sent to your e-mail address.",
    labels: {
      email: "Email",
      pass: "Password",
      name: "Name",
      surname: "Surname",
      phone: "Phone",
      pass_again: "Password Again",
      pass_new: "New Password",
      pass_new_again: "New Password Again",
      remember: "Remember Me",
      forgot_pass: "I Forgot my Password",
      dont_account: "Don't have an account",
      already_account: "Do you already have an account",
      remember_pass: "Do you remember your password",
      code: "Password Reset Code",
    },
    legals: {
      confidentiality_agreement: "Privacy Policy",
      terms_of_use: "Terms of Use",
    },
  },

  err404: "404",
};
