import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Modal, Button } from "antd";
import { Link } from "react-router-dom";
import parseHtml from "html-react-parser";
// Actions
import { hideDialog } from "../redux/actions";
// Images
import LoginSVG from "../assets/images/svg/login-dialog.svg";
// Locale
import I18n from "../locale";
// Helpers
import { post } from "../helpers/http.helper";

export default function DialogBox() {
  // Aktif olan Dialog Box ismi.
  const dialog = useSelector((state) => state.dialogReducer);
  // Site Ayarlari
  let [settings, setSettings] = useState();
  // Site Ayarlarının Çekilmesi
  async function getSettings() {
    await post("settings", dialog?.data ? dialog.data : {}).then((res) => {
      if (res.result) {
        setSettings(res.settings);
        console.log(res)
      }
    });
  }
  useEffect(() => {
    getSettings();
  }, [dialog]);

  if (dialog.name !== "")
    return (
      <>
        <Modal
          className="app-modal"
          title={false}
          visible={dialog.name == "message"}
          onCancel={hideDialog}
          footer={false}
        >
          <p className="text-center my-3">{dialog.data?.message}</p>
          <div className="text-right">
            <Button className="btn btn-muted mr-3" onClick={hideDialog}>
              {I18n.t("no")}
            </Button>
            <Button
              className="btn btn-primary"
              onClick={() => {
                dialog.data?.onClick();
                hideDialog();
              }}
            >
              {I18n.t("yes")}
            </Button>
          </div>
        </Modal>

        <Modal
          className="app-modal"
          title={false}
          visible={dialog.name == "login"}
          onCancel={hideDialog}
          footer={false}
        >
          <div style={{ width: "100%", padding: "30px 60px" }}>
            <img src={LoginSVG} width="100%" />
          </div>
          <Link to="/auth/login" onClick={hideDialog}>
            <Button className="btn btn-secondary btn-block mb-3">
              {I18n.t("auth.login")}
            </Button>
          </Link>
          <Link to="/auth/register" onClick={hideDialog}>
            <Button className="btn btn-primary btn-block">
              {I18n.t("auth.register")}
            </Button>
          </Link>
        </Modal>

        <Modal
          className="app-modal"
          title={false}
          visible={dialog.name == "change"}
          onCancel={hideDialog}
          footer={false}
        >
          <div className="w-100 text-muted text-center mt-3">
            {I18n.t("save_changes")}
          </div>
          <div className="text-right mt-4">
            <Button
              className="btn btn-outline-primary mr-3"
              onClick={hideDialog}
              style={{ textTransform: "uppercase" }}
            >
              {I18n.t("no")}
            </Button>
            <Button
              className="btn btn-primary"
              onClick={() => {
                dialog.data?.onOk();
                hideDialog();
              }}
              style={{ textTransform: "uppercase" }}
            >
              {I18n.t("yes")}
            </Button>
          </div>
        </Modal>

        <Modal
          width={1000}
          className="app-modal"
          title={
            <h4 className="w-100 text-center m-0">
              {I18n.t("auth.legals.terms_of_use")}
            </h4>
          }
          visible={dialog.name == "terms_of_use"}
          onCancel={hideDialog}
          footer={false}
        >
          <div className="w-100 text-muted" style={{ textAlign: "justify" }}>
            {settings ? parseHtml(settings?.terms_of_use) : null}
          </div>
        </Modal>

        <Modal
          width={1000}
          className="app-modal"
          title={
            <h4 className="w-100 text-center m-0">
              {I18n.t("auth.legals.confidentiality_agreement")}
            </h4>
          }
          visible={dialog.name == "confidentiality_agreement"}
          onCancel={hideDialog}
          footer={false}
        >
          <div className="w-100 text-muted" style={{ textAlign: "justify" }}>
            {settings ? parseHtml(settings?.confidentiality_agreement) : null}
          </div>
        </Modal>

        <Modal
          width={1000}
          className="app-modal"
          title={
            <h4 className="w-100 text-center m-0">
              {I18n.t("shop.distance_selling")}
            </h4>
          }
          visible={dialog.name == "distance_selling"}
          onCancel={hideDialog}
          footer={false}
        >
          <div className="w-100" style={{ textAlign: "justify" }}>
            {settings ? parseHtml(settings?.distance_sales_agreement) : null}
          </div>
        </Modal>
      </>
    );
  else return null;
}
