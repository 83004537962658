import store from "../store";

/**/
var SET_SHOPPING = "SET_SHOPPING";
var CLEAR_SHOPPING = "CLEAR_SHOPPING";
var ADD_SHOPPING = "ADD_SHOPPING";
var REMOVE_SHOPPING = "REMOVE_SHOPPING";

/**/
export function setShopping(shopping) {
  store.dispatch({
    type: SET_SHOPPING,
    shopping,
  });
}
export function clearShopping() {
  store.dispatch({
    type: CLEAR_SHOPPING,
  });
}
export function addShopping(id) {
  store.dispatch({
    type: ADD_SHOPPING,
    id: id,
  });
}
export function removeShopping(id) {
  store.dispatch({
    type: REMOVE_SHOPPING,
    id: id,
  });
}
