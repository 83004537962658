import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import { BrowserRouter as Router } from "react-router-dom";
import { Provider } from "react-redux";
// Store
import store from "./redux/store";
//Styles
import "antd/dist/antd.css";
import "animate.css";
import "react-multi-carousel/lib/styles.css";
import "react-modal-video/scss/modal-video.scss";
import "./assets/scss/whatsapp.scss";
import "./assets/scss/style.scss";
//Components
import RouterScrollReset from "./components/RouterScrollReset";

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <Router>
        <RouterScrollReset>
          <App />
        </RouterScrollReset>
      </Router>
    </Provider>
  </React.StrictMode>,
  document.getElementById("root")
);
