import React from "react";
import { Route, Switch } from "react-router";
//Layouts
import { AuthLayout, MainLayout } from "../layouts";

export default function Routes() {
  
  return (
    <Switch>
      <Route path="/auth" render={(props) => <AuthLayout {...props} />} />
      <Route path="/" render={(props) => <MainLayout {...props} />} />
    </Switch>
  );
}
