import { combineReducers } from "redux";
//Reducers
import testReducer from "./test.Reducer";
import loadingReducer from "./loading.Reducer";
import userReducer from "./user.Reducer";
import dialogReducer from "./dialog.Reducer";
import shoppingReducer from "./shopping.Reducer";
import settingsReducer from "./settings.Reducer";

//
const combinedReducers = combineReducers({
  testReducer,
  loadingReducer,
  userReducer,
  dialogReducer,
  shoppingReducer,
  settingsReducer,
});
export default combinedReducers;
