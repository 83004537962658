var initial_state = {
  shopping: [],
};

export default function shoppingReducer(state = initial_state, action) {
  switch (action.type) {
    case "SET_SHOPPING":
      localStorage.setItem("shopping", JSON.stringify(action.shopping));
      return { ...state, shopping: action.shopping };
    case "CLEAR_SHOPPING":
      localStorage.setItem("shopping", JSON.stringify([]));
      return initial_state;
    case "ADD_SHOPPING":
      localStorage.setItem(
        "shopping",
        JSON.stringify([...state.shopping, ...[{ id: action.id }]])
      );
      return {
        ...state,
        shopping: [...state.shopping, ...[{ id: action.id }]],
      };
    case "REMOVE_SHOPPING":
      localStorage.setItem(
        "shopping",
        JSON.stringify([
          ...state.shopping.slice(
            0,
            state.shopping.findIndex((item) => item.id === action.id)
          ),
          ...state.shopping.slice(
            state.shopping.findIndex((item) => item.id === action.id) + 1
          ),
        ])
      );
      return {
        ...state,
        shopping: [
          ...state.shopping.slice(
            0,
            state.shopping.findIndex((item) => item.id === action.id)
          ),
          ...state.shopping.slice(
            state.shopping.findIndex((item) => item.id === action.id) + 1
          ),
        ],
      };

    default:
      return state;
  }
}
