import store from "../store";

/**/
var SET_SETTINGS = "SET_SETTINGS";
/**/
export function setSettings(settings) {
  store.dispatch({
    type: SET_SETTINGS,
    settings,
  });
}
