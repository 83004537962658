export default {
  navbar: {
    home: "Ana Sayfa",
    about: "Hakkımızda",
    support: "Destek Talebi",
    info: "Bilgi Merkezi",
    general_info: "Genel Bilgiler",
    product_info: "Ürün Bilgileri",
    documents: "Dökümanlar",
    settings: "Ayarlar",
    profile: "Profilim",
    signout: "Çıkış Yap",
    login: "Giriş Yap",
    register: "Kayıt Ol",
  },
  header: {
    recommend: "Sizin İçin Önerdiklerimiz",
  },
  footer: {
    contact: "İletişim",
    pages: "Sayfalar",
    text: "Müşterilerimize en kaliteli hizmeti sunmayı misyon edinerek, müşteri memnuniyetini her zaman her şeyden üstün tutmayı hedefliyoruz",
  },

  previous: "Önceki",
  next: "Sonraki",
  unsp: "Belirtilmemiş",
  month: "Ay",
  save: "Kaydet",
  cancel: "İptal",
  add_basket: "Sepete Ekle",
  remove_basket: "Sepetten Kaldır",
  type_product: "Urun",
  type_software: "Yazılım",
  very_soon: "Çok Yakında",
  contact_me: "İletişime Geç",
  contact_for_demo: "Demo İçin İletişime Geçiniz",
  no_results: "Sonuc Bulunamadi",
  download_file: "Dosyayı İndir",
  go_invoice: "Faturaya Git",
  go_document: "Dökümana Git",
  yes: "Evet",
  no: "Hayır",
  save_changes: "Değişiklikler Kaydedilsin mi?",
  and: "ve",

  for_once: "Tek Seferlik",
  monthly: "Aylık",
  no_surprise: "Sürpriz Ücret Yok",
  new: "Yeni",

  pages: {
    login: "Gıriş Yap",
    register: "Kayıt Ol",
    password_forgot: "Şifremi Unuttum",
    home: "Ana Sayfa",
    about: "Hakkımızda",
    info: "Bilgi Merkezi",
    general_info: "Genel Bilgiler",
    product_info: "Ürün Bilgileri",
    documents: "Dökümanlar",
    support: "Destek Talebi",
    basket: "Sepetim",
    pay: "Ödeme Yap",
    billing_info: "Fatura Bilgisi",
    profile: "Profilim",
    payments: "Ödemelerim",
  },
  home: {
    all: "Tümü",
    sug_rank: "Önerilen Sıralama",
    inc_price: "Artan Fiyat",
    desc_price: "Azalan Fiyat",
  },
  account: {
    bar: {
      profile: "PROFİLİM",
      products: "ÜRÜNLERİM",
      payments: "ÖDEMELERİM",
      support: "DESTEK TALEBİ",
      settings: "AYARLAR",
      signout: "ÇIKIŞ YAP",
    },
    settings: {
      account_info: "Hesap Bilgileri",
      change_email: "E-Posta Değiştir",
      change_pass: "Şifre Değiştir",
      labels: {
        name: "Ad",
        surname: "Soyad",
        phone: "Telefon",
        country: "Ülke",
        company: "Şirket",
        current_email: "Mevcut E-Posta",
        new_email: "Yeni E-Posta",
        current_pass: "Mevcut Şifre",
        new_pass: "Yeni Şifre",
        new_pass_again: "Yeni Şifre Tekrar",
      },
    },
    profile: {
      email: "E-Posta",
      company: "Şirket",
      phone: "Telefon",
      country: "Ülke",
      reg_date: "Kayıt Tarihi",
    },
    products: {
      preparing: "Hazırlanıyor",
      use: "Kullanılıyor",
      total_time: "Toplam Süre",
      remaining_time: "Kalan Süre",
      start_date: "Başlangıç Tarihi",
      end_date: "Bitiş Tarihi",
      order_date: "Sipariş Tarihi",
      expired: "Süresi Doldu",
      returned: "İade Edildi",
      return: "İade Et",
      returned_message: "Ürünü İade etmek istediğinizden eminmisiniz.",
    },
    payments: {
      date: "Ödeme Tarihi",
      amount: "Toplam Tutar",
      installment: "Taksit",
    },
  },
  invoice: {
    details: "Fatura Detayları",
    number: "Ftaura No",
    name: "İsim",
    surname: "Soyisim",
    phone: "Telefon",
    city: "İl",
    district: "İlçe",
    post_code: "Posta Kodu",
    address: "Adres",
    date: "Tarih",
    datatable: {
      product: "Ürün",
      package: "Paket",
      time: "Süre(Ay)",
      price: "Fiyat",
      start_date: "Başlangıç Tarihi",
      end_date: "Bitiş Tarihi",
    },
    shoptable: {
      number: "Toplam Ürün Adedi",
      amount: "Toplam Tutar",
    },
  },
  supports: {
    head: "Size Nasıl Yardımcı Olabiliriz",
    head2: "Destek Taleplerim",
    text: "Müşterilerimize en kaliteli hizmeti sunmayı misyon edinerek, müşteri memnuniyetini her zaman her şeyden üstün tutmayı hedefliyoruz.",
    create: "Destek Talebi Oluştur",
    search: "Destek Talebi Ara",
    status: "Durum",
    title: "Başlık",
    description: "Açıklama",
    send_date: "Gönderim Tarihi",
    send: "Destek Talebini Gönder",
    waiting: "Yanıt Bekleniyor",
    answered: "Yanıtlandırıldı",
    solved: "Çözüldü",
    new_message: "Yeni Mesaj",
    send_new_message: "Yeni Mesaj gonder",
  },
  product: {
    info: "Ürün Bilgileri",
    view: "Ürünü İncele",
    document: "Dökümanı İncele",
    features: "Belirgin Özellikleri",
    prices: "Fiyatlarımız",
    price_table: {
      features: "Özellikler",
    },
    shipping_fee: "Kargo Ücreti",
    installment: "Taksit",
  },
  shop: {
    basket: "Sepetim",
    clean: "Sepeti Temizle",
    view: "Ürünleri İncele",
    search: "Sepette Ara",
    confirm_cart: "Sepeti Onayla",
    confirm: "Onayla",
    no_item_found: "Sepetinizde Urun Bulunamadi",

    read_and_accept: "Okudum ve kabul ediyorum.",
    distance_selling: "Mesafeli Satış Sözleşmesi",

    payment_options: "Taksit Secenekleri",
    installment: "Taksit",
    oneshot: "Tek Cekim",

    order_info: "Sipariş Bilgileri",
    delivery_info: "Teslimat Bilgileri",
    payment_info: "Ödeme Bilgileri",
    gotocart: "Sepete Git",
    gotoproduct: "Ürüne Git",

    pay: "Ödeme Yap",
    continue: "Devam Et",
    goback: "Geri Git",

    datatable: {
      product: "Ürün",
      package: "Paket",
      time: "Süre(Ay)",
      piece: "Adet",
      price: "Fiyat",
    },
    shopcart: {
      number: "Toplam Ürün Adedi",
      subtotal: "Ara Toplam",
      amount: "Toplam Tutar",
    },
    labels: {
      city: "İl",
      district: "İlçe",
      post_code: "Posta Kodu",
      address: "Adres",
      additional_info: "Ek Bilgi",
      card_number: "Kart Numarası",
      exp_date: "Son Kullanma Tarihi",
    },
  },
  auth: {
    login: "Giriş Yap",
    register: "Kayıt Ol",
    send: "Gönder",
    verify: "Doğrula",
    change_my_password: "Sifremi Degistir",
    heads: {
      login: "GİRİŞ YAP",
      register: "KAYIT OL",
      forgot_pass: "ŞİFREMİ UNUTTUM",
    },
    forgot_text:
      "Lütfen e-mail adresinizi giriniz. E-posta yoluyla yeni bir şifre oluşturmak için bir kod alacaksınız.",
    forgot_code_text:
      "Son bir adım kaldı. E-Posta adresinize yolladığımız kodu girerek şifrenizi yenileyebilirsiniz.",
    labels: {
      email: "E-Posta",
      pass: "Şifre",
      name: "Ad",
      surname: "Soyad",
      phone: "Telefon",
      pass_again: "Şifre Tekrar",
      pass_new: "Yeni Şifre",
      pass_new_again: "Yeni Şifre Tekrar",
      remember: "Beni Hatırla",
      forgot_pass: "Şifremi Unuttum",
      dont_account: "Hesabın Yok mu",
      already_account: "Zaten bir Hesabın mı var",
      remember_pass: "Parolanızı hatırlıyor musunuz",
      code: "Şifre Sıfırlama Kodu",
    },
    legals: {
      confidentiality_agreement: "Gizlilik Politikası",
      terms_of_use: "Kullanıcı Koşulları",
    },
  },

  err404: "404",
};
