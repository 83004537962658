import store from "../store";

/**/
var SHOW_LOADING = "SHOW_LOADING";
var HIDE_LOADING = "HIDE_LOADING";
var SHOW_LOADER = "SHOW_LOADER";
var HIDE_LOADER = "HIDE_LOADER";
/**/
export function showLoading(time = 0) {
  store.dispatch({
    type: SHOW_LOADING,
  });
  if (time > 0) hideLoading(time);
}

export function hideLoading(time = 0) {
  if (time > 0) {
    setTimeout(() => {
      store.dispatch({
        type: HIDE_LOADING,
      });
    }, time);
  } else {
    store.dispatch({
      type: HIDE_LOADING,
    });
  }
}

export function showLoader(name = "*", time = 0) {
  store.dispatch({
    type: SHOW_LOADER,
    name,
  });
  if (time > 0) hideLoading(name, time);
}

export function hideLoader(name = "*", time = 0) {
  if (time > 0) {
    setTimeout(() => {
      store.dispatch({
        type: HIDE_LOADER,
        name,
      });
    }, time);
  } else {
    store.dispatch({
      type: HIDE_LOADER,
      name,
    });
  }
}
