var initial_state = {
  settings: {},
};

export default function settingsReducer(state = initial_state, action) {
  switch (action.type) {
    case "SET_SETTINGS":
      return { settings: { ...state.settings, ...action.settings } };
    default:
      return state;
  }
}
