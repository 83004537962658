var initial_state = {
  loaders: [],
  loader: false,
  loading: false,
};

export default function loadingReducer(state = initial_state, action) {
  switch (action.type) {
    case "SHOW_LOADING":
      return { ...state, loading: true };
    case "HIDE_LOADING":
      return { ...state, loading: false };
    case "SHOW_LOADER":
      return {
        ...state,
        loader: true,
        loaders:
          action.name !== "*"
            ? [...state.loaders, action.name]
            : [...state.loaders],
      };
    case "HIDE_LOADER":
      let hide_index = state.loaders.indexOf(action.name);
      let _loaders =
        action.name !== "*"
          ? [
              ...state.loaders.slice(0, hide_index),
              ...state.loaders.slice(hide_index + 1),
            ]
          : [];
      let _loader = true;
      if (_loaders.length == 0) _loader = false;
      return { ...state, loader: _loader, loaders: _loaders };

    default:
      return state;
  }
}
