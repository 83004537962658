import React, { useState, useEffect, Suspense } from "react";
import * as config from "../constants/configs";
// AOS
import AOS from "aos";
import "aos/dist/aos.css";
// Hooks
import useNetwork from "../hooks/use-network";
import useQuery from "../hooks/use-query";
// Routes
import Routes from "./routes";
// Components
import { DarkLoading, LightLoading } from "../components/Loading";
import DialogBoxs from "../components/DialogBoxs";
// Locale
import I18n, { default_language } from "../locale";
// Moment
import "moment/locale/tr";
import "moment/locale/en-gb";
import moment from "moment";
// Helpers
import { get } from "../helpers/http.helper";
// Actions
import { setSettings } from "../redux/actions";

export default function App() {
  let query = useQuery();

  //AOS
  useEffect(() => {
    AOS.init({
      offset: 60,
      duration: 600,
      once: true,
      easing: "ease",
    });
    AOS.refresh();
  }, []);

  // Network Connection
  useNetwork();

  // Site Ayarlarının Çekilmesi
  async function getSettings() {
    await get("settings").then((res) => {
      if (res.result) {
        setSettings(res.settings);
      }
    });
  }

  // Locale
  let lang =
    query.get("lang") || localStorage.getItem("lang") || default_language;
  // Locale Set Change
  useEffect(() => {
    I18n.locale = lang;
    localStorage.setItem("lang", lang);
    moment.locale(lang);
    document.documentElement.lang = lang;
    getSettings();
  }, [lang]);

  return (
    <>
      <Suspense fallback={<DarkLoading />}>
        <LightLoading />
        <DialogBoxs />
        <Routes />
      </Suspense>
      <div
        class="phone-call cbh-phone cbh-green cbh-show  cbh-static"
        id="clbh_phone_div"
        style={{ marginRight: "5%", position: "fixed", zIndex: "80" }}
      >
        <a
          id="WhatsApp-button"
          href="https://wa.me/905438391116?text=Merhaba"
          target="_blank"
          class="phoneJs"
          title="Mesaj Gönder"
          rel="noopener noreferrer"
        >
          <div class="cbh-ph-circle"></div>
          <div class="cbh-ph-circle-fill"></div>
          <div class="cbh-ph-img-circle1"></div>
        </a>
      </div>
    </>
  );
}
