import { api_key, api_url } from "../constants/configs";
import ax from "axios";
import { message } from "antd";
//Locale
import { default_language } from "../locale";
// Actions
import {
  showLoader,
  hideLoader,
  showLoading,
  hideLoading,
} from "../redux/actions";
// Utiliates
import RandomInterval from "../utilities/randomInterval";

const axios = ax.create({
  baseURL: api_url + "/",
});

const lang = localStorage.getItem("lang") || default_language;

axios.interceptors.request.use(
  (config) => {
    config.headers.common["x-access-token"] = `Bearer ${localStorage.getItem(
      "userToken"
    )}`;
    config.headers.common["x-api-key"] = api_key;
    config.headers.common["locale"] = lang;
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

async function post(endpoint, data) {
  showLoading();
  return await axios.post(endpoint, data).then((res) => {
    hideLoading();
    showMessage(res.data);
    return res.data;
  });
}
async function put(endpoint, data) {
  showLoading();
  return await axios.put(endpoint, data).then((res) => {
    hideLoading();
    showMessage(res.data);
    return res.data;
  });
}
async function get(endpoint, data) {
  let name = endpoint + RandomInterval(100000, 999999);
  showLoader(name);
  return await axios.get(endpoint, { headers: data }).then((res) => {
    hideLoader(name, 500);
    showMessage(res.data);
    return res.data;
  });
}
async function del(endpoint, data) {
  showLoading();
  return await axios.delete(endpoint, { headers: data }).then((res) => {
    hideLoading();
    showMessage(res.data);
    return res.data;
  });
}

export { post, put, get, del };

const showMessage = (data) => {
  if (data.message) {
    if (data.result) {
      message.success(data.message);
    } else if (!data.result) {
      message.error(data.message);
    }
  }
};
